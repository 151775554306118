import * as React from "react";
import {useEffect} from "react";

const DGLock = () => {
  useEffect(() => {
    let osana = document.getElementsByClassName('osano-cm-window__dialog')[0];
    osana.setAttribute('style', 'visibility:hidden;');
  }, []);
  return (
      <div className="dglock" style={{
        width: '100%', textAlign: 'center', marginTop: '4rem', fontSize: 'x-large', fontFamily: 'open sans,sans-serif'
      }}>
        <section className="hero">
          <div className="container">
            <h1>
              We Have Moved!
            </h1>
            <p>
              DG LOCK has changed its name to The Keyless Store and can be reached at <a
                href={'https://www.keyless.co.nz/'} target={'_blank'} rel={'noreferrer'}>www.keyless.co.nz</a>
            </p>
            <p>DG LOCK/The Keyless Store is not affiliated in any way with Digilock brand electronic locking devices. If
              you are seeking Digilock, please visit <a
                  href="https://www.digilock.com/">www.digilock.com</a></p>
          </div>
        </section>
      </div>

  )
}
export default DGLock;
